import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import loadGTM from './common/loadGTM.js'
import izintaLogo from './img/izinta.svg';
import logo from './img/logo-header.svg';
import logoSolid from './img/logo-solid.png';
import logoSlogen from './img/nasodren-logo.png';
import eco from './img/np_natural.svg';
import product from './img/nasodren-product.png';
import property_1 from './img/1.svg';
import property_2 from './img/2.svg';
import property_3 from './img/3.svg';
import property_4 from './img/4.svg';
import npNat from './img/np_nat.svg';
import npClock from './img/np_clock.svg';
import cpOk from './img/np_ok.svg';
import nasodren2 from './img/nasodren2_ok.jpg';
import flavor from './img/ciklamen.png';
import download from './img/letoltes.svg';
import downloadTest from './img/teszt-letoltes.svg';
import downloadUsage from './img/hasznalati-letoltes.svg';
import eye from './img/szem.svg';
import face from './img/arc.svg';
import cake from './img/15.svg';
import usage from './img/hasznalat.jpg';
import 'normalize.css';
import './grid.css';

function App() {
  const [openNav, setOpenNav] = useState(false);

  const toggleNav = () => {
    setOpenNav(!openNav);
  }

  useEffect(() => {
    loadGTM(() => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    })
  }, [])

  return (
    <div className={`app ${openNav ? 'open-nav' : ''}`}>
      <header className="header">
        <div className="header-bg"></div>
        <div className="header-menu">
          <nav className="main-menu mobile-menu">
            <ul>
              <li className="first"><Link activeClass="active" to="ciklamen" spy={true}>A ciklámen</Link></li>
              <li><Link activeClass="active" to="arcureggyulladasrol" spy={true}>Az arcüreggyulladásról</Link></li>
              <li><Link activeClass="active" to="gyakori-tanacsok" spy={true}>Gyakorlati tanácsok</Link></li>
              <li className="last"><a href="https://www.izinta.hu/hu/kapcsolat/kapcsolat" target="_blank" rel="noreferrer">Kapcsolat</a></li>
            </ul>
          </nav>

          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="logo">
                  <img src={logo} alt="Nasodren logo" />
                </div>
              </div>
              <div className="col-md-8">
                <nav className="main-menu desktop-menu">
                  <ul>
                    <li className="first"><Link activeClass="active" to="ciklamen" spy={true}>A ciklámen</Link></li>
                    <li><Link activeClass="active" to="arcureggyulladasrol" spy={true}>Az arcüreggyulladásról</Link></li>
                    <li><Link activeClass="active" to="gyakori-tanacsok" spy={true}>Gyakorlati tanácsok</Link></li>
                    <li className="last"><a href="https://www.izinta.hu/hu/kapcsolat/kapcsolat" target="_blank" rel="noreferrer">Kapcsolat</a></li>
                  </ul>
                </nav>

                <div className="hamburger" onClick={toggleNav}>
                  <div className="hamburger-elem"></div>
                  <div className="hamburger-elem"></div>
                  <div className="hamburger-elem"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-content">
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-md-12 justify-content-center align-self-center">
                <img src={product} alt="Nasodren illusztráció" className="img-auto" />
              </div>
              <div className="col-md-6 order-md-1 justify-content-center align-self-center">
                <h1>NASODREN orrspray</h1>
                <p className="subtitle">Nyákoldásra, valamint az orr és az orrmelléküregek tisztítására</p>

                <div className="eco"><img src={eco} alt="Eco ikon" width={35} height={35} /> 100%-ban természetes összetevők</div>

                <button className="btn btn-primary">Keresse a patikákban!</button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="properties-wrapper">
        <div className="container">
          <div className="properties">
            <div className="row">
              <div className="col-md-12 col-lg-3 justify-content-center align-self-center">
                <div className="property"><img src={property_1} width={50} height={50} alt="Tulajdonság ikon" /> Napi egyszeri használat</div>
              </div>
              <div className="col-md-12 col-lg-3 justify-content-center align-self-center">
                <div className="property"><img src={property_2} width={50} height={50} alt="Tulajdonság ikon" /> 100%-ban természetes ciklámenkivonat</div>
              </div>
              <div className="col-md-12 col-lg-3 justify-content-center align-self-center">
                <div className="property"><img src={property_3} width={50} height={50} alt="Tulajdonság ikon" /> Felnőtteknek és 5 évesnél idősebb gyermekeknek</div>
              </div>
              <div className="col-md-12 col-lg-3 justify-content-center align-self-center">
              <div className="property"><img src={property_4} width={50} height={50} alt="Tulajdonság ikon" /> Nem tartalmaz tartósítószert</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="ciklamen" className="content-section content-light">
        <div className="container">
          <div className="row">
            <div className="col-md-4 order-md-12 justify-content-center align-self-center">
              <img src={flavor} alt="Ciklámen" className="img-auto app-image" />
            </div>
            <div className="col-md-8 order-md-1 justify-content-center align-self-center">
              <h3 className="title">A ciklámen</h3>

              <p>A NASODREN egy vizes oldat, amely a Cyclamen europaeum L. növény friss gumóinak természetes kivonatából készült. A termék semmilyen más összetevőt vagy tartósítószert nem tartalmaz.</p>

              <h4>A ciklámen hatékony segítség több, mint 1500 éve</h4>
              <p>A ciklámen orrnyálkahártyára kifejtett hatása több, mint ezer éve ismert: mézzel keverve a görögök már a III.-IV. században alkalmazták hurutos orrdugulás és fejfájás kezelésére. Később, a XVI. és a XX. században grúz tudósok számos alkalommal leírták a ciklámenkivonat kedvező hatását az orrgarati problémák kezelése során. Napjainkra tökéletesítették a hatóanyag kinyerésének technológiáját, így egy kedvezőbb árfekvésű, valódi megoldást nyújtó eszköz született az orrüregben összegyűlő nyák oldására.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="content-section content-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 justify-content-center align-self-center">
              <div className="property property-primary"><img src={npNat} width={50} height={50} alt="Tulajdonság ikon" /> 100%-BAN TERMÉSZETES</div>
            </div>
            <div className="col-md-12 col-lg-4 justify-content-center align-self-center">
              <div className="property property-primary"><img src={npClock} width={50} height={50} alt="Tulajdonság ikon" /> HOSSZÚTÁVON ALKALMAZHATÓ</div>
            </div>
            <div className="col-md-12 col-lg-4 justify-content-center align-self-center">
              <div className="property property-primary"><img src={cpOk} width={50} height={50} alt="Tulajdonság ikon" /> NAPI EGYSZERI ADAGOLÁS</div>
            </div>
          </div>
        </div>
      </div>

      <div id="arcureggyulladasrol" className="content-section content-light">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h3 className="title">Mi az az orr- és arcüreggyulladás?</h3>

              <p>A nátha (rhinitis) és az arcüreggyulladás (sinusitis) a legtöbb betegnél egyszerre jelentkezik. Az orr- és arcüreggyuladás (rhinosinusitis) egy gyulladásos folyamat, amely az orr- és az arcüreg nyálkahártyáját érinti. Az orr- és arcüreggyuladás akut vagy krónikus állapot is lehet, attól függően, hogy 12 hétnél rövidebb vagy hosszabb ideig állnak fenn a tünetek. A diagnózist elsősorban a fennálló tünetek kombinációja határozza meg.</p>

              <h4>Az alábbiak közül két tünet fennállása esetén beszélünk orr- és arcüreggyuladásról:</h4>
              <div className="row">
                <div className="col-md-6">
                  <ul className="colored-list">
                    <li>Orrdugulás</li>
                    <li>Orrnyálkahártya-duzzanat</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="colored-list">
                    <li>Orrelzáródás</li>
                    <li>Orr váladékozása</li>
                  </ul>
                </div>
              </div>

              <h4>További lehetséges tünetek:</h4>
              <div className="row">
                <div className="col-md-6">
                  <ul className="colored-list">
                    <li>+/- arcfájdalom vagy nyomás az arcüregben</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="colored-list">
                    <li>+/- a szaglás csökkenése vagy elvesztése</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <img src={nasodren2} alt="Nasodren illustration" className="img-auto app-image" />
            </div>
          </div>
        </div>
      </div>

      <div className="content-section content-highlight">
        <div className="container">
          <div className="row">
            <div className="col-md-7 justify-content-center align-self-center">
              <div className="download-text-wrapper">
                <a href={process.env.PUBLIC_URL + '/files/teszt.pdf'} target="_blank" rel="noreferrer" className="download-text"><img src={download} width={55} height={55} alt="Nasodren illustration" /> <div>ISMERJE FEL ÁLLAPOTÁT!<br />TÖLTSE LE ARCÜREGGYULLADÁS TESZTÜNKET!</div></a>
              </div>
            </div>
            <div className="col-md-3 justify-content-center align-self-center" style={{ textAlign: 'center' }}>
              <img src={downloadTest} width={164} height={129} alt="Nasodren illustration" className="mobile-none" />
            </div>
          </div>
        </div>
      </div>

      <div className="content-section content-light content-center">
        <div className="container">
          <h3 className="title" style={{ marginBottom: 0 }}>Tudta?</h3>
          <h3 style={{ marginTop: 0, marginBottom: 42 }}>Tények az orr- és arcüreggyulladásról</h3>

          <div className="row">
            <div className="col-md-6 col-lg-4 justify-content-center align-self-center">
              <div className="property property-secondary"><img src={eye} width={80} height={80} alt="Tulajdonság ikon" /> Az arcüreggyulladás az egyik leggyakoribb megbetegedés, amit gyakran nem is ismernek fel a betegek.</div>
            </div>
            <div className="col-md-6 col-lg-4 justify-content-center align-self-center">
              <div className="property property-secondary"><img src={face} width={80} height={80} alt="Tulajdonság ikon" /> Becslések szerint egy felnőtt évente 2-5 alkalommal szenved akut vírusos arcüreggyulladásban.</div>
            </div>
            <div className="col-md-6 col-lg-4 justify-content-center align-self-center">
              <div className="property property-secondary"><img src={cake} width={80} height={80} alt="Tulajdonság ikon" /> Krónikus arcüreggyulladás a felnőtt lakosság mintegy 15%-ánál fordul elő élete során.</div>
            </div>
          </div>

          <p className="legal">1 Fokkens W, Lund V, Mullol J. European position paper on Rhinosinusitis and nasal polyps 2007. Rhinology 2007. Suppl. 20: 1-136.</p>
        </div>
      </div>

      <div className="content-section content-dark content-center">
        <div className="container">
          <div style={{ textAlign: 'center' }}>
            <img src={logoSlogen} alt="Nasodren - Az arcüreggyulladás természetes kezelése" />

            <div style={{ marginTop: 64 }} dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.youtube.com/embed/PPT3fdIvXa8" width="100%" height="512" frameborder="0" allowfullscreen="allowfullscreen"></iframe>' }} />

            <div style={{ marginTop: 64 }} dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.youtube.com/embed/J33-LFFM4iM" width="100%" height="512" frameborder="0" allowfullscreen="allowfullscreen"></iframe>' }} />
          </div>
        </div>
      </div>

      <div className="content-section content-dark-2">
        <div className="container">
          <div className="center">
            <button className="btn btn-primary" style={{ display: 'inline-block' }}>Keresse a patikákban!</button>
          </div>
        </div>
      </div>

      <div id="gyakori-tanacsok" className="content-section content-light content-center">
        <div className="container">
          <h3 className="title">Gyakorlati tanácsok a Nasodren használatához</h3>
          <ul>
            <li>Az összekevert oldatot naponta csak egyszer szabad használni az egyes orrlyukakba, lehetőleg minden nap azonos időpontban, körülbelül két órával lefekvés előtt.</li>
            <li>A napi egynél többszöri használat nem eredményez nagyobb hatékonyságot, ezért azt kerülni kell.</li>
            <li><b>A spray használata során ne vegyen lélegzetet, mert ez intenzívebbé teheti és hosszabb ideig fenntarthatja az említett hatásokat.</b></li>
            <li>A spray alkalmazása után néhány perccel rövid, enyhe és átmeneti viszketés, égő érzés, tüsszögés, könnyezés és az orrváladék fokozódása fordulhat elő. Ezek a termékre adott pozitív reakció jelei. E hatások részben a hideg hőmérsékletnek tudhatók be, így azok intenzitásának csökkentésére vegye ki a terméket a hűtőszekrényből a használat előtt 2-3 perccel.</li>
            <li>A spray-t 7-10 napig kell használni, szükség esetén a kezelés 14 napra meghosszabbítható. Amennyiben ennél tovább is szükséges, a terméket ezután csak 7-10 napos várakozási időt követően szabad újra alkalmazni.</li>
            <li>A NASODREN felnőtteknél és 5 éves vagy annál idősebb gyermekeknél alkalmazható. Gyermekek csak felnőtt felügyelete mellett használhatják.</li>
            <li>Ne használja a NASODREN-t, ha allergiás vagy túlérzékeny a ciklámenre, kankalinra, illetve a kankalinfélék családjának egyéb tagjaira.</li>
          </ul>

          <p style={{ textAlign: 'center', fontSize: 20 }}><b>Kövesse az alábbi képeken jelzett utasításokat az eszköz alkotóelemeinek összekeveréséhez, mielött azt az orrlyukba történő bepermetezésre használná.</b></p>

          <img src={usage} className="center img-auto" style={{ marginBottom: 22 }} alt="Nasodren - Használat" />

          <b>Az oldat elkészítésének menete</b>
          <ol>
            <li>Nyissa ki a port tartalmazó injekciós üveget a kupak jobbra forgatásával és a dugó eltávolításával.</li>
            <li>Nyissa fel a folyadékot tartalmazó műanyag palackot a palackot fedő rész letörésével.</li>
            <li>Öntse a teljes folyadékot a port tartalmazó üvegfiolába.</li>
            <li>Csavarja rá a permetezo fúvókát az üvegfiolára, és finoman rázza fel, amíg a por teljesen fel nem oldódik. Várja meg, amíg a keletkező hab eltűnik.</li>
            <li>Távolítsa el a védokupakot a permetező fúvókáról.</li>
            <li>Az orrban történő első használat előtt 2-3 alkalommal nyomja meg a permetező fúvókát, a testtől távolabb a levegőbe irányítva, a szemet elkerülve!</li>
          </ol>

          <b>Hogyan kell használni a NASODREN-t?</b>
          <ol start="7">
            <li>A fejét tartsa függőlegesen, ne hajoljon előre vagy hátra. Helyezze a szórófejet a jobb orrlyukba. Lélegezzen mélyet, és rövid ideig (3-5 másodpercig) tartsa vissza a lélegzetét, ezalatt egyszer permetezze a NASODREN-t a jobb orrlyukba. Lélegezzen ki mélyen szájon át egy alkalommal, ezután térjen vissza a normális légzéshez. Az orrspray használata során ne vegyen lélegzetet.</li>
            <li>Ismételje meg a spray használatát a bal orrlyukba, 7. pontban leírtak szerint.</li>
            <li>Tisztítsa meg a szórófejet tiszta papírzsebkendovel, majd helyezze vissza a fúvóka védokupakját.</li>
          </ol>

          <div className="download-usage-wrapper">
            <a href={process.env.PUBLIC_URL + '/files/B11564-02_nasodren_betegtajekoztato_HU.pdf'} target="_blank" rel="noreferrer" className="usage-download"><img src={downloadUsage} height={32} alt="Letöltés ikon" /> HASZNÁLATI ÚTMUTATÓ LETÖLTÉSE</a>
          </div>
        </div>
      </div>

      <div className="content-section content-information">
        <div className="container">
          <p>A KOCKÁZATOKRÓL OLVASSA EL A HASZNÁLATI ÚTMUTATÓT, VAGY KÉRDEZZE MEG KEZELŐORVOSÁT!</p>
        </div>
      </div>

      <div className="content-section content-brand">
        <div className="container">
          <div className="row">
            <div className="col-md-4 justify-content-center align-self-center">
              <img src={logoSolid} alt="Nasodren logo" />
            </div>
            <div className="col-md-8 justify-content-center align-self-center">
              <p className="healing">Gyógyászati segédeszköz</p>
            </div>
          </div>
        </div>
      </div>

      <footer className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 order-md-2 justify-content-center align-self-center" style={{ textAlign: 'center' }}>
              <a href="https://www.izinta.hu" target="_blank" rel="noreferrer"><img src={izintaLogo} height={64} alt="Izinta logo" /></a>
            </div>

            <div className="col-md-4 order-md-1 justify-content-center align-self-center">
              <div className="distributor">
                <b>Forgalmazza az Izinta Kft.</b>
                <span>1121 Budapest, Konkoly-Thege Miklós út 29-33.</span><br />
                <span><a href="tel:3613922654">+36 1 392 2654</a></span><br />
                <span><a href="mailto:info@izinta.hu">info@izinta.hu</a></span>
              </div>
            </div>

            <div className="col-md-4 order-md-3 justify-content-center align-self-center">
              <div className="distributor distributor-right">
                <b>www.izinta.hu</b>
                <span><a href="https://www.izinta.hu/hu/altalanos-szerzodesi-feltetelek" target="_blank" rel="noreferrer">Általános szerződési feltételek</a></span><br />
                <span><a href="https://www.izinta.hu/hu/adatfelhasznalasi-szabalyzat" target="_blank" rel="noreferrer">Adatkezelési tájékoztató</a></span><br />
                <span><a href="https://www.izinta.hu/hu/kapcsolat/kapcsolat" target="_blank" rel="noreferrer">Kapcsolat</a></span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
